
import { defineComponent, ref, reactive, computed, toRef, inject } from 'vue'

import { useStore } from 'vuex'
import DatePicker from '@/views/components/datePicker.vue'
import moment from 'moment'
import { modalInstance, notifyInstance } from '@/type'
import axios from '@/http'
import { useRoute, useRouter } from 'vue-router'
import exportExcel from '@/plugins/exportExcel'
import helper from '@/helper'

moment.locale('zh-tw')

export default defineComponent({
  components: {
    DatePicker
  },
  props: ['propEmployees'],
  setup(props, { emit }) {
    const notify = inject('notify') as notifyInstance
    const modal = inject('modal') as modalInstance

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const nowStore = computed(() => store.getters.nowStore)
    const storeId = computed(() => nowStore.value.id)
    const exportType = ref('Oversold')
    const oversoldRecords = ref(new Array<any>())

    const date = ref(moment().startOf('day').format('YYYY-MM-DD'))

    function getOversoldRecords() {
      axios
        .get(`/oversold/${storeId.value}`, {
          params: {
            date: date.value
          }
        })
        .then((res) => {
          const data = res.data
          oversoldRecords.value = data.oversoldRecords
        })
        .catch((err) => {
          notify(
            {
              classType: 'error',
              message: `資訊載入失敗，${err.response.errors.detail}`,
              closeAble: true
            },
            60000
          )
        })
    }

    function updateDate(updateDate: any) {
      date.value = moment(updateDate).format('YYYY-MM-DD')
      getOversoldRecords()
    }

    function nextDate() {
      date.value = moment(date.value).add(1, 'day').format('YYYY-MM-DD')
    }

    function preDate() {
      date.value = moment(date.value).subtract(1, 'day').format('YYYY-MM-DD')
    }

    const monthStart = computed(() =>
      moment(date.value).startOf('month').format('YYYY-MM-DD')
    )
    const monthEnd = computed(() =>
      moment(date.value).endOf('month').format('YYYY-MM-DD')
    )

    function exportExcelClick() {
      if (
        !helper.isAdmin() &&
        !helper.isStoreAdmin() &&
        !helper.hasExportPermission(route.meta.permission as string)
      ) {
        modal({
          content: '權限不足</br>' + '請與商店管理者確認權限</br>',
          confirmBtnText: '確定',
          classType: 'alert',
          cancelBtn: false
        }).show()
      } else {
        exportExcel(
          exportType.value,
          storeId.value,
          {
            date: date.value
          },
          modal,
          notify,
          router
        )
      }
    }

    function getProductStandardName(product: any) {
      if (product.products_standard_main != null) {
        if (product.products_standard_sub != null) {
          return `(${product.products_standard_main}-${product.products_standard_sub})`
        }
        return `(${product.products_standard_main})`
      }
      return ''
    }

    getOversoldRecords()

    return {
      date,
      nextDate,
      preDate,
      updateDate,
      monthStart,
      monthEnd,
      exportExcelClick,
      oversoldRecords,
      getProductStandardName
    }
  }
})
